import {Component} from '@angular/core';
import { getAnalytics, logEvent } from "@angular/fire/analytics";


import {locale as englishLang} from "../assets/i18n/es";
import {locale as spanishLang} from "../assets/i18n/es";
import { TranslationService } from './shared/services/translation.service';


declare global {
    interface Window {
        onloadTurnstileCallback: () => void;
        turnstile: {
            render: (idOrContainer: string | HTMLElement, options: any) => string;
            reset: (widgetIdOrContainer: string | HTMLElement) => void;
            getResponse: (widgetIdOrContainer: string | HTMLElement) => string | undefined;
            remove: (widgetIdOrContainer: string | HTMLElement) => void;
        };
    }
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = 'Luna Nueva';

    constructor(private translationService: TranslationService) {
        this.translationService.addTranslation(englishLang, spanishLang);
        logEvent(getAnalytics(), "Página cargada");
    }

    
}
