import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription, switchMap, take } from "rxjs";
import { ContactFormUseCaseService } from "../../core/usecase/contact-form-use-case.service";
import { ModalService } from "../../shared/services/modal.service";
import { DialogService } from "../../shared/services/dialog.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ContactFormModel } from "../../core/domain/contact-form-model";
import { ContactFormTexts } from "../../shared/components/services-form/contact-form-texts";
import { PropertyDetailPostModel } from '../../core/domain/property-detail-post-model';
import { AlojamientosRepository } from '../../core/repositories/alojamientos-post.repository';
import { StoreFiltersService } from '../../shared/services/storeFilters.service';

import { calcularPrecioTotalConTarifas, calcularTotalNoches } from '../../shared/helpers/helpers';
import { consumerAfterComputation } from '@angular/core/primitives/signals';

@Component({
  selector: 'app-contact-seller-page-form',
  templateUrl: './contact-seller-page-form.component.html',
  styleUrls: ['./contact-seller-page-form.component.css']
})
export class ContactSellerPageFormComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  title: string = 'Contacta al vendedor';
  message: string = '';
  postId: string = '';
  loading: boolean = false;
  post$!: Observable<PropertyDetailPostModel>;
  card: PropertyDetailPostModel | null | undefined;
  fromDate: any;
  toDate: any;
  cantidadNoches: number = 1;
  total = 0;
  totalNoches = 0;
  tarifaLimpieza = 0;
  tarifaServicio = 0;
  porcentajeLunaNueva = 0.03;
  precioNoche = 0;

  constructor(
    private _contactFormUseCaseService: ContactFormUseCaseService,
    private _modal: ModalService,
    private _dialogService: DialogService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _activateRoute: ActivatedRoute,
    private _alojamientosRepository: AlojamientosRepository,
    private storeFilters: StoreFiltersService,

  ) {

    let suscription = this._activateRoute.queryParams.subscribe(params => {
      this.postId = params['id'];
    });
    this.subscriptions.push(suscription);
  }

  calculateTotal() {
    let oferta


    if (this.card) {
      if (this.card.ofertas.length > 0) {
        oferta = this.card.ofertas[0].porcentajeDescuento;
      } else {
        oferta = null;
      }
      this.precioNoche = this.card.tarifas[0].precioNoche;
      this.totalNoches = calcularTotalNoches(this.precioNoche, oferta, this.cantidadNoches);
      this.tarifaLimpieza = this.card.tarifaLimpieza;
      this.tarifaServicio = this.porcentajeLunaNueva * this.totalNoches;
      this.total = this.totalNoches + this.tarifaServicio + parseFloat(this.tarifaLimpieza.toString());
      this.tarifaServicio = parseFloat(this.tarifaServicio.toFixed(2));
    }
  }



  calcularPrecioTotal() {
    if (this.card) {
      this.precioNoche = this.card.tarifas[0].precioNoche;
      let oferta = 0;
      if (this.card.ofertas.length > 0) {
        oferta = this.card.ofertas[0].porcentajeDescuento;
        this.precioNoche = calcularTotalNoches(this.card.tarifas[0].precioNoche, oferta);
      }
      const calculated = calcularTotalNoches(this.card.tarifas[0].precioNoche, oferta, this.cantidadNoches);
      this.totalNoches = calculated;
      this.tarifaLimpieza = this.card.tarifaLimpieza;
      //this.definePrice(this.precioTotalNoches.toString());
      this.getTotal(calculated);
    }

  }

  getTotal(totalNights: number) {
    //Se incluye la tarifa de limpieza?
    this.tarifaServicio = (totalNights + 0) * this.porcentajeLunaNueva;
    this.tarifaServicio = parseFloat(this.tarifaServicio.toFixed(2));
    this.total = calcularPrecioTotalConTarifas(this.totalNoches, this.tarifaServicio, Number(this.tarifaLimpieza));
    this.getCantidadNoches();
    const dataToSend = {

      night: this.cantidadNoches.toString(),
      nightPrice: this.precioNoche.toString(),
      nightTotal: this.totalNoches.toString(),
      total: this.total.toString(),
      cleaningFee: this.tarifaLimpieza.toString(),
      serviceFee: this.tarifaServicio.toString(),
      bedrooms: this.card?.cantidadCamas?.toString(),
      bathrooms: this.card?.cantidadBanos?.toString(),
    };

  }


  getCantidadNoches() {
    this.fromDate = this.storeFilters.getFilter().fromDate;
    this.toDate = this.storeFilters.getFilter().toDate;
    // Convertir las cadenas de texto a objetos Date
    let checkIn = new Date(this.fromDate);
    let checkOut = new Date(this.toDate);
    // Calcular la diferencia de tiempo en milisegundos
    const diffTime = Math.abs(checkOut.getTime() - checkIn.getTime());

    // Convertir la diferencia de tiempo en días y redondear hacia arriba
    if (isNaN(Math.ceil(diffTime / (1000 * 60 * 60 * 24)))) {
      console.error("error de fechas");
      this.cantidadNoches = 1;
    }
    else {
      this.cantidadNoches = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    }
  }


  sendEmails($item: any) {
    const {total}=$item;

    const urlImgMail = this.card?.imagenes[0].url.lg;
    const { captcha, ...rest } =JSON.parse(JSON.stringify($item));
    delete rest['services'];

    const updatedRest = {
      ...rest,
      location: this.card?.propiedades.direccion,
      fullDirection: `${this.card?.propiedades.direccion}, ${this.card?.propiedades.ciudad.nombre}`,
    };


    // Aquí puedes incluir los nuevos campos en el objeto rest
    const dataToSend = {
      ...updatedRest,
      urlImgMail,
      night: $item.cantidadNoches.toString(),
      nightPrice: $item.precioPorNoche,
      nightTotal: $item.precioTotalNoches,
      total: total.toString(),
      cleaningFee: $item.tarifaLimpieza.toString(),
      serviceFee:  $item.tarifaLunaNueva.toString(),
      bedrooms: this.card?.cantidadCamas?.toString(),
      bathrooms: this.card?.cantidadBanos?.toString(),
    };



    this.subscriptions.push(
      this._contactFormUseCaseService.postEmailSellerContact(this.postId, dataToSend, captcha)
        .subscribe({
          next: (data) => {
            this._dialogService.openInfoDialog(ContactFormTexts.infoDialog.buttonText, '', ContactFormTexts.infoDialog.textBody, ContactFormTexts.infoDialog.textHeader)
              .pipe(take(1))
              .subscribe(res => this.goToHomePage());
          },
          error: () => {
            this._modal.show('contactPageForm.textErrorOccur', 'contactPageForm.textErrorExplanation', "ERROR", 5);
          }
        })
    );
  }


  ngOnDestroy() {
    if (this.subscriptions)
      this.subscriptions.forEach(subs => subs.unsubscribe());
  }

  goToHomePage(): void {
    this._router.navigate(['/']);
  }

  ngOnInit(): void {
    this.getDetails();
    setTimeout(() => {
     // this.calcularPrecioTotal();
    }, 250);
  }

  getDetails() {

    this.loading = true;
    this.post$ = this._route.queryParamMap.pipe(
      switchMap(params => {
        const idAlojamiento = params.get('id')!;
        return this._alojamientosRepository.getPostDetails(idAlojamiento);
      })
    );
    this.post$.subscribe(post => {
      this.loading = false;
      this.card = post;
      this.calcularPrecioTotal();
      
    });
  }


}
