export const locale = {
  lang: 'es',
  data: {
    "general": {
      "labelEmail": "Correo electrónico",
      "labelUserProfile": "Perfil de Usuario",
      "labelIndicateType": "Indicar si es inmobiliaria, vendedor, o propietario",
      "textVirtualReality": "Realidad virtual",
      "textUsed": "De uso",
      "textNew": "Nuevo",
      "labelRent": "en renta",
      "labelSale": "en venta",
      "textBackHome": "Volver al Home"
    },
    "header": {
      "linkContact": "Contáctanos",
      "linkServices": "Servicios",
      "linkCreateAccount": "Crea tu cuenta",
      "linkLogin": "Iniciar sesión",
      "linkLogout": "Cerrar sesión",
      "linkMyAccount": "Mi cuenta",
      "linkMyData": "Mis datos",
      "linkMyFavorites": "Mis Favoritos",
      "linkMyReserves": "Mis Reservas",
      "btnPublishProperty": "Publicar propiedad",
      "textConfirmCloseSession": "¿Estás seguro que quieres cerrar sesión?",
      "textCloseSession": "Si, cerrar sesión",
      "textNoBack": "No, volver",
      "textTooltip": "Te faltan datos por completar en la sección “Mi cuenta”."
    },
    "headerMenu": {
      "textSignIn": "Crear cuenta",
      "textMyAccount": "Mi cuenta",
      "textMyReservations": "Mis reservas",
      "textMyFavourites": "Mis favoritos",
      "textServices": "Servicios",
      "textLogIn": "Iniciar sesión",
      "textLogOut": "Cerrar sesión"
    },
    "footer": {
      "text1": "Gestiona tus reservas de alojamiento de forma sencilla y personalizada en Guatemala con nosotros.",
      "text2": "personalizada en Guatemala con nosotros.",
      "linkDevelopedBy1": "Desarrollado con",
      "linkDevelopedBy2": "por Seiza. Todos los derechos reservados",
      "linkContact": "Contáctanos",
      "linkServices": "Servicios",
      "linkPublishProperty": "Publica tu propiedad"
    },
    "home": {
      "bannerTitle": "¡Bienvenido a ",
      "bannerTitle2": "Luna Nueva Properties!",
      "bannerText": "Reserva tu alojamiento ideal en Antigua Guatemala.",
      "bannerFirst": "Alojamientos",
      "bannerSecond": "Selecciona fechas para tu alojamiento",
      "btnContactUs": "Contáctanos",
      "seeAll": "Ver todo",
      "allAccommodations": "Todos los alojamientos",
      "bannerPromoTitle": "¿Representas a una empresa y no encuentras en dónde hospedar a tus clientes en Antigua Guatemala?",
      "bannerPromoText": "¡Contáctanos! Podemos crear un paquete especial para tus necesidades."
    },
    "searchHeader": {
      "textProperties": "Propiedades",
      "textBuyingRenting": "¿Estás pensando en comprar o rentar? Revisa las propiedades que seleccionamos para ti"
    },
    "searchHeaderMobile": {
      "textSeeMoreFilter": "Ver más filtros"
    },

    "sortPosts": {
      "textOrderBy": "Ordenar por",
      "newest": "Más recientes",
      "lowerPrice": "Menor precio",
      "higherPrice": "Mayor precio",
      "older": "Más antiguas"
    },
    "searchBar": {
      "labelSearchByLocation": "Buscar por locación",
      "labelChooseOption": "Elige una opción",
      "labelPropertyType": "Tipo de propiedad",
      "labelPriceRange": "Rango de precios",
      "placeHolderSelectLocation": "Seleccionar locación",
      "placeHolderLike": "¿Qué te gustaría?",
      "placeHolderChooseProperty": "Elige tu propiedad",
      "placeHolderChooseRange": "Elige tu rango de precios",
      "subtitle": "Precios por noche sin incluir impuestos y tarifas",
      "btnSearch": "Buscar",
      "btnSearchInMap": "Buscar en mapa",
      "map": "Mapa",
      "textRent": "Rentar",
      "textBuy": "Comprar",
      "filters": "Filtros",
      "clearFilters": "Limpiar Filtros",
      "textDateTitle": "Fecha de llegada y salida",
      "textNights": "Noches",
      "textHint": "Fecha desde - Hasta",
      "textChoose": "Agregar Fechas",
      "textSelect": "Selecciona fechas para tu alojamiento"
    },
    "guestSelector": {
      "textTitle": "Cantidad de huéspedes",
      "textButton": "Seleccionar huéspedes",
      "textGuests": "Huéspedes",
      "textAdults": "Adultos",
      "textChildren": "Niños",
      "textInfants": "Bebés",
      "textPets": "Mascotas",
      "textBottom1": "En Luna Nueva Properties también aceptamos ",
      "textBottom2": "Animales de Servicio",
      "textAge1": "Edad: 13 años o más",
      "textAge2": "Edad: De 2 a 12 años",
      "textAge3": "Edad: De 0 a 2 años"
    },
    "searchOption": {
      "textTitle": "Elige el tipo de lugar de tu preferencia:",
      "textApartment": "Apartamento",
      "textHouse": "Casa",
      "textBed&Breakfast": "Bed and Breakfast"
    },
    "rangePrice": {
      "from": "Desde",
      "to": "Hasta"
    },
    "rentOptions": {
      "rent": "Tiempo de estadía",
      "one_month": "Un Mes",
      "three_months": "Tres Meses",
      "more_than_three_months": "Más de 3 Meses",
      "one_year": "1 Año"
    },
    "modalPets": {
      "title": "Animales de servicio",
      "text1": "La Ley de Protección y Bienestar Animal de Guatemala, Decreto 5-2017, reconoce a los animales de servicio como una forma de apoyo para las personas con discapacidades.",
      "text2": "La ley establece que los animales de servicio tienen derecho a acceder a lugares públicos, como restaurantes, tiendas y transporte público.",
      "close": "Cerrar",
    },
    "modalHints": {
      "title": "Tarifa por servicio de Luna Nueva",
      "text1": "En Luna Nueva destinamos una parte de nuestros recursos para apoyar a artesanos y artistas independientes de Guatemala, brindándoles capacitación y soporte para promover sus productos alrededor del mundo.",
      "close": "Cerrar",
    },
    "card": {
      "labelSeeMore": "Ver más",
      "labelBedroom": "habitación",
      "labelBedrooms": "habitaciones",
      "labelBathroom": "baño",
      "labelBathrooms": "baños",
      "linkDoTour": "Hacer tour 360º",
      "linkVirtualReality": "Realidad Virtual",
      "textPostDeactivated": "Publicación desactivada",
      "textPostNoAvailable1": "¡Lo sentimos! Esta publicación ya no",
      "textPostNoAvailable2": "está disponible, puede que se haya",
      "textPostNoAvailable3": "vendido o haya sido desactivada.",
      "textDelete": "Eliminar",
      "labelIn": "en",
      "labelHouse": "Casa",
      "labelApartment": "Apartamento",
      "labelDiscount": "Oferta",
      "labelPricePerNight": "Precio por noche",
      "labelPrice": "Precio ",
      "labelFor": "por",
      "labelNight": "noche",
      "labelNights": "noches",
      "labelAdults": "Adultos",
      "labelAdult": "Adulto",
      "labelGuest": "Huesped",
      "labelGuests": "Huespedes",
      "labelTaxes": "Incluye impuestos y cargos",
      "textDistance1": "A",
      "textDistance2": "del centro",
      "textFromPrice": "Precios desde",
      "textBooking": "Reservar",
      "textTarifaLimpieza": "Tarifa de limpieza",
      "textTarifaLunaNueva": "Tarifa por servicio de Luna Nueva",
      "textTotalImpuestos": "Total (impuestos incluídos)",
      "textTotalImpuestosOferta": "Total oferta (impuestos incluídos)",
      "textTooltip": "En Luna Nueva Properties destinamos una parte de nuestros recursos para apoyar a artesanos y artistas independientes en Guatemala.",
      "textMoreInfo": "Más información",
    },
    "cardMap": {
      "labelBathrooms": "baños"
    },
    "servicesForm": {
      "back": "Volver",
      "labelContactForm": "Formulario de contacto",
      "labelFullName": "Nombre y apellido",
      "labelTelephone": "Teléfono",
      "textProfileType": "Arrendador / Comprador",
      "labelOptionToBeContacted": "Elige una opción por la que te gustaría ser contactado",
      "labelSendMeACopy": "Envíame una copia",
      "btnSend": "Enviar",
      "labelTour": "Servicios tour 360º",
      "labelVirtualReality": "Servicios realidad virtual",
      "labelPhotography": "Servicios fotografía",
      "labelLegalServices": "Servicios legales",
      "labelSellProperty": "Vendemos tu propiedad",
      "labelRentProperty": "Rentamos tu propiedad",
      "labelPostProperty": "Publica tu propiedad",
      "labelMessage": "Mensaje",
      "labelManagement": "Administración y gestión de propiedades",
      "labelDesign": "Diseño de interiores"
    },
    "fieldInput": {
      "textSaveContinue": "Guardar y Continuar",
      "textContinue": "Continuar",
      "textCancel": "Cancelar",
      "textLoading": "Cargando...",
      "msgRequiredField": "Campo requerido",
      "textInvalidEmailFormat": "Formato de email inválido",
      "textInvalidDpiFormat": "Formato de DPI inválido",
      "msgOnlyNumbers": "Debe contener un código de área + 8 dígitos",
      "msgYahooEmailNotAllowed": "Correos de Yahoo no son permitidos. Por favor use otro proveedor.",
      "labelPassword": "Contraseña",
      "labelRepeatPassword": "Repetir contraseña",
      "msgPasswordError": "La contraseña debe contener: 8 caracteres como mínimo, una letra mayúscula y un número.",
      "msgPasswordMatch": "Las contraseñas deben coincidir",
      "msgPassword": "Debe contener mínimo 8 caracteres, una mayúscula y un número",
      "labelName": "Nombre",
      "lastName": "Apellido",
      "labelDpiPassport": "Número de documento",
      "labelDocumentType": "Tipo de documento",
      "textSave": "Guardar",
      "textPassport": "Pasaporte"
    },
    "register": {
      "textTitle": "Completa tus datos",
      "textMyAccount": "Mi cuenta",
      "textPersonalInformation": "Datos personales",
      "msgName": "Ingrésalo como aparece en tu documento",
      "textTermAndConditions1": "Acepto los ",
      "textTermAndConditions2": "Términos y Condiciones ",
      "textTermAndConditions3": "y autorizo el uso de mis datos de acuerdo a la ",
      "textTermAndConditions4": "Declaración de Privacidad",
      "ex": "Ex",
      "labelNationality": "Nacionalidad",
      "msgAccessToEmail": "Asegúrate de tener acceso a este correo electrónico",
      "msgDpiPassport": "Seleccionar DPI o Pasaporte",
      "msgDocument": "Escribe el número de tu DPI o pasaporte Ej.: 2320 55634 0103",
      "msgNationality": "Seleccionar nacionalidad",
      "buttonText": "Cerrar",
      "textHeader": "La solicitud ha sido enviada a tu correo electrónico",
      "textBody": "Dirígete a tu bandeja de entrada para validar tu correo. Si no lo encuentras revisa tu carpeta de correos no deseados",
      "infoConditions": "Debe aceptar los Términos y Condiciones.",
      "infoError": "No hemos podido registrar el usurio, intentelo nuevamente.",
      "infoFormInvalid": "Para continuar debes llenar todos los campos del formulario",
      "infoFormInvalidData": "Luna Nueva no hará uso de tus datos sin habértelo consultado previamente",
      "infoErrorUserExist": "Ya existe una cuenta con el correo electrónico ",
      "infoErrorUserExistData": "Si tienes una cuenta en Luna Nueva Properties, no necesitas crear una nueva, inicia sesión con ese correo.",
      "textButtonUserExist": "Usar otro correo",
      "textButtonUserExistInitialSession": "Iniciar sesión",
      "textInformation": "Información",
      "textPhoneForm": "Lo usaremos solo en caso de solicitar información adicional.",
      "textAlerInformation": "Guarda tus datos para reservar más rápido la próxima vez.",
      "textIdUpload": "Agregar documento de identidad",
      "textHover": "Para la reserva será necesario este documento de identidad.",
      "textFormat": "Formatos JPG, PNG o PDF con un peso máximo de 6MB",
      "textFieldRequired": "Campo requerido",
      "textSee": "Ver",
      "textDelete": "Eliminar",
      "interior": {
        "id": "my-account-desing",
        "title": "Mi cuenta",
        "breadCrumb": "Mi cuenta"
      },
      "textFileSuccess": "Archivo cargado con éxito",
      "textFileError": "Error al cargar archivo",
      "textDataUpdated": "Datos actualizados",
      "textDataError": "Error al actualizar datos",
      "textUploading": "Subiendo Archivo...",
    },
    "signIn": {
      "textWelcome": "¡Bienvenido! Ingresa tu correo electrónico",
      "textRestorePwd": "Recupera tu contraseña",
      "textCreateAccount": "Crear cuenta",
      "textIncorrectData": "Datos Incorrectos",
      "textInvalidCredentials": "Credenciales Inválidas",
      "verifyEmail": "Verifica tu correo electrónico"
    },
    "emailVerification": {
      "textVerifyingEmail": "¡Estamos verificando su correo electrónico, espere por favor.",
      "textCongratulations": "Felicidades, validamos tu correo electrónico",
      "textDescription": "Ahora puedes ingresar a la plataforma con tu cuenta.",
      "textError": "Error Verificando",
      "textErrorDescription": "Ocurrió un error verificando su correo."
    },
    "emailSendVerification": {
      "helpVerifyEmail": "Ayuda para verificar tu correo electrónico",
      "textAccountEmail": "Escribe la dirección de correo electrónico asociado a tu cuenta de Luna Nueva Properties",
      "textSuccessRequest": "La solicitud de validar correo ha sido enviada a tu correo electrónico",
      "textGoToInbox": "Dirígete a tu bandeja de entrada y sigue las instrucciones para validar tu cuenta.",
      "textErrorRequest": "Error enviando solicitud de validar correo",
      "textErrorDescription": "Ocurrió un error verificando su correo."
    },
    "forgotPassword": {
      "textHelpWithPwd": "Ayuda con la contraseña",
      "textAccountEmail": "Escribe la dirección de correo electrónico asociado a tu cuenta de Luna Nueva Properties",
      "textGoToInbox": "Dirígete a tu bandeja de entrada y sigue las instrucciones para restablecer tu contraseña.",
      "textSuccessRequest": "La solicitud de recuperar contraseña ha sido enviada a tu correo electrónico",
      "textErrorRequest": "Error enviando solicitud de recuperar contraseña",
      "textErrorDescription": "Un error ha ocurrido al enviar la solicitud de recuperar contraseña",
      "textEmailNotFound": "El correo electrónico no fue encontrado",
    },
    "resetPassword": {
      "textRestorePwd": "Reestablecer contraseña",
      "textNewPwd": "Escribe una nueva contraseña",
      "textSave": "Guardar",
      "textError": "Error cambiando contraseña",
      "textErrorDescription": "Puede que este enlace haya expirado"
    },
    "filtersSideMenu": {
      "textApartmentsForSale": "Apartamentos en venta",
      "textPublishedToday": "Publicados hoy",
      "textTerrace": "Terraza",
      "textGarden": "Jardín",
      "textBedroom": "dormitorio",
      "textBedrooms": "dormitorios",
      "textBathroom": "baño",
      "textBathrooms": "baños",
      "textCondition": "Condición",
      "textStudio": "Monoambiente",
      "textMore": "o más",
      "textNew": "Nuevo",
      "textUsed": "Usado",
      "textTypeOfLease": "Tipo de arriendo",
      "textTraditional": "Tradicional",
      "textNoParking": "No tiene parking",
      "textFilterBy": "Filtrar por",
      "textWorkspace": "Área de trabajo",
      "textWifi": "Wifi",
      "textPool": "Piscina",
      "textSmokerFriendly": "Apto para fumadores",
      "textParkingInTheAccommodation": "Parking en el alojamiento",
      "textOtherServices": "Otros servicios",
      "textWashingMachine": "Lavadora",
      "textDryerMachine": "Secadora",
      "textKitchen": "Cocina",
      "textFridge": "Nevera",
      "textTv": "Tv",
      "textMicrowave": "Microondas",
      "textBalcony": "Balcon",
      "textHeating": "Calefacción",
      "textAirConditioner": "Aire acondicionado",
      "textSofa": "Sofá Cama",
      "textElevatorEntrance": "Acceso al ascensor",
      "textHairDryer": "Secadora de cabello",
      "textIron": "Plancha",
      "textGrill": "Asador",
      "textSecurity": "Seguridad",
      "textSmokeDetector": "Detector de humo",
      "textCarbonMonoxideDetector": "Detector de monoxido de carbono",
      "textApplyFilters": "Aplicar filtros",
      "textClearFilters": "Limpiar filtros",
      "textBeds": "Camas",
      "textQueenBed": "Cama queen",
      "textKingBed": "Cama king",
      "textIndividualBed": "Cama individual",
      "textMatrimonialBed": "Cama matrimonial",
      "textSharedBathroom": "Baño compartido",
      "textPrivateBathroom": "Baño privado",
      "textMeditationArea": "Zona de meditación"
    },
    "filtersSideNavMobile": {
      "labelAdditionalFilters": "Filtros adicionales",
      "labelSelectFilters": "Selecciona los filtros para tu búsqueda",
      "labelApplyFilters": "Aplicar filtros"
    },
    "postDetails": {
      "textBack": "Volver al listado",
      "textPublished": "Publicado hace",
      "textAgo": "hace",
      "textBy": "por",
      "textByNight": "noche",
      "linkDoTour": "Hacer tour 360º",
      "textContactSeller": "Contactar al vendedor",
      "textContact": "Contáctanos",
      "textLocation": "Ubicación",
      "textDescription": "Descripción",
      "textOtherCharacteristics": "Otras características",
      "textBBServices": "Servicios de la habitación",
      "textServices": "Servicios del alojamiento ",
      "textSharedAreas": "Áreas comunes",
      "textYouMustKnow": "Lo que debes saber",
      "textBBPolitics": "Políticas del Bed and Breafast",
      "textPolitics": "Políticas del alojamiento",
      "textZoneInfo": "Información de la zona",
      "labelBedroom": "habitación",
      "labelBedrooms": "habitaciones",
      "labelBathroom": "baño",
      "labelBathrooms": "baños",
      "textGarden": "Con jardín",
      "textTerrace": "Con terraza",
      "textYear": "año",
      "textYears": "años",
      "textMonth": "mes",
      "textMonths": "meses",
      "textDay": "día",
      "textDays": "días",
      "textHour": "hora",
      "textHours": "horas",
      "textMinute": "minuto",
      "textMinutes": "minutos"
    },
    "propertyCharacteristics": {
      "textCharacteristics": "Características",
      "totalArea": "Superficie total",
      "textUsefulSurface": "Superficie útil",
      "textTerraceArea": "Superficie de terraza",
      "textBedrooms": "Cuartos",
      "textBathrooms": "Baños",
      "textMaximumNumberOfInhabitants1": "Cantidad máxima",
      "textMaximumNumberOfInhabitants2": "de habitantes",
      "textUnitFloorNumber1": "Número de piso",
      "textUnitFloorNumber2": "de la unidad",
      "textApartmentsPerFloor1": "Apartamentos",
      "textApartmentsPerFloor2": "por piso",
      "textNumberOfFloors": "Cantidad de pisos",
      "textCondition": "Condición",
      "textUsed": "De uso",
      "textNew": "Nuevo",
      "textOrientation": "Orientación",
      "textRealStateInformation": "Información de la inmobiliaria",
      "textGotoRealStatePro": "Ir al perfil de la inmobiliaria",
      "textViewMore": "Ver más publicaciones de",
      "textPropertyCode": "Código de la propiedad",
      "textShare": "Compartir"
    },
    "propertyRequirements": {
      "textRequirements1": "Requerimientos para ",
      "textRequirements2": "esta propiedad",
      "textShowIncome": "Muestra tus ingresos:",
      "textDocumentsWeNeed": "Documentos que necesitamos:",
      "textRent": "rentar",
      "textBuy": "comprar"
    },
    "propertyReservation": {
      "textNight": "noche",
      "textNights": "noches",
      "textCleaningFee": "Tarifa de limpieza",
      "textLunaNuevaService": "Tarifa por servicio de Luna Nueva",
      "textGoBack": "Volver",
      "textSaveAndSend": "Guardar datos y enviar solicitud de reserva",
      "textYourData": "Tus datos",
      "textContactUs": "¿Dudas o preguntas? Contáctanos",
      "textTitle": "Confirmación y datos",
      "textYourTrip": "Tu viaje"
    },
    "reservationSuccess": {
      "textTitle": "La solicitud de reserva ha sido enviada con éxito",
      "textToReservations": "Llévame a mis reservas"
    },
    "reservationError": {
      "title": "No pudimos enviar la solicitud de reserva.",
      "text": "Por favor inténtalo nuevamente.",
      "retry": "Reintentar"
    },
    "reservationPriceChanged": {
      "textTitle": "Cambio de precio",
      "text1": "Te escribimos para informarte que tu reserva tiene un cambio de precio:",
      "textOldPrice": "Antes",
      "textNewPrice": "Ahora",
      "textPerNight": "por noche",
      "text2": "Este cambio se debe a las actualizaciones realizadas dentro de la propiedad",
      "text3": "Ten en cuenta esta información antes de continuar con tu solicitud de reserva",
      "textUnderstood": "Entendido"
    },
    "successfulContact": {
      "textTitle": "El formulario ha sido enviado con éxito",
      "text1": "En breve te contactaremos para atender tu solicitud y responder todas tus preguntas",
      "close": "Cerrar"
    },
    "characteristicsList": {
      "textNoCharacteristics": "Esta propiedad todavía no tiene características"
    },
    "postsNotFound": {
      "textNoProperties1": "No existen propiedades con las características",
      "textNoProperties2": "solicitadas, por favor modifica tu búsqueda e",
      "textNoProperties3": "intenta nuevamente"
    },
    "realState": {
      "posts": " publicaciones",
      "found": "Encuentra tu corredor de propiedades favorito: ",
      "properties": "Ver Propiedades",
      "viewAll": "Ver todos los corredores e inmobiliarias"
    },
    "realStateBAB": {
      "posts": " publicaciones",
      "found": "Encuentra tu Bed and Breakfast favorito: ",
      "properties": "Ver Propiedades",
      "viewAll": "Ver todos los Bed and Breakfast"
    },
    "googleMap": {
      "textExist": "Existen",
      "textPropertyInArea": " propiedades en esta área",
      "textNoProperties": "No existen propiedades en esta área"
    },
    "favorites": {
      "path": "Mis búsquedas > Favoritos",
      "textFavorites": "Favoritos",
      "textNoFavorites": "No tienes alojamientos favoritos",
      "textBackHome": "Volver"
    },
    "notFound": {
      "textPageNotFound": "¡Ups! No se encuentra esta página",
      "textDeletedPage": "Es posible que se haya eliminado la página que buscas"
    },
    "services": {
      "textServices": "Servicios",
      "textMore": "Más información",
      "tour-360": {
        "title": "Tour 360º",
        "titleIframe": "Demostración del Tour 360º",
        "textOne": " Elimina las barreras de espacio o de tiempo con tus clientes potenciales",
        "textTwo": " Diseño, producción y realización de recorridos virtuales 360º de alto impacto para casas, apartamentos, edificios y otros inmuebles",
        "subtitle": "Elimina las barreras de espacio o de tiempo con tus clientes potenciales",
        "paragraphs": {
          "first": "Diseño, producción y realización de recorridos virtuales 360º de alto impacto para casas, apartamentos, edificios y otros inmuebles.",
          "second": "Con nuestros recorridos virtuales en 360",
          "third": "Con nuestros recorridos virtuales en 360 tus futuros compradores, inquilinos o el inversionista recorrerán tu propiedad en cualquier momento desde cualquier dispositivo electrónico (computadora / smartphone / tableta)."
        },
        "subtitleTwo": "Algunos beneficios:",
        "list": {
          "item1": "Se eliminan las limitaciones de tiempo y ubicación de compradores potenciales.",
          "item2": "Aumenta la confianza sobre tu portafolio",
          "item3": "Aumenta el alcance de tu negocio, pues permite que todos los usuarios puedan ver lo que vendes. ",
          "item4": "Eleva las posibilidades de vender, aumentando el número de visitas a los inmuebles. ",
          "item5": "Reduce tiempo en las transacciones comerciales, pues ni el cliente ni el corredor inmobiliario tienen que hacer ",
          "item6": "coincidir su agenda para realizar la visita.",
          "item7": "Disminuye los costos y esfuerzos el agente inmobiliario, pues no debe dedicar tiempo en la visita o gastos para el desplazamiento.",
          "item8": "Aumenta la productividad de los agentes inmobiliarios y de la empresa.",
          "item9": "Es compatible con los diferentes canales digitales más recomendados para la comercialización de inmuebles."
        }
      },
      "vr": {
        "title": "Realidad virtual",
        "textOne": "Presenta a tus inversionistas su futuro proyecto de inversión desde cualquier lugar y en cualquier momento,  sin depender de un avance físico",
        "textTwo": "Con este recorrido inmersivo tu cliente podrá moverse por las instalaciones a su gusto, visitar cada área e interactuar con objetos del entorno sin moverse de tu oficina.",
        "subtitle": "Presenta a tus inversionistas su futuro proyecto de inversión desde cualquier lugar y en cualquier momento, sin depender de un avance físico",
        "paragraphs": {
          "first": "Con este recorrido inmersivo tu cliente podrá moverse por las instalaciones a su gusto, visitar cada área e interactuar con objetos del entorno sin moverse de tu oficina. El recorrido virtual utilizando la tecnología RV es una herramienta estratégica de alto impacto para vender proyectos inmobiliarios a inversionistas y clientes potenciales pues puedes explicar el proyecto como nunca antes lo habías hecho. "
        },
        "subtitleTwo": "Algunos beneficios:",
        "list": {
          "item1": "Reducir tiempo y dinero al iniciar un desarrollo para empezar a generar ventas.Presenta el proyecto sin depender de avances físicos.",
          "item2": "Mejora el contacto con tus inversionistas.Tus inversionistas podrán observar con detenimiento que cambios se hacen al diseño del proyecto, además de seguir el desarrollo inmobiliario.",
          "item3": "Eleva las posibilidades de vender, aumentando el número de visitas a los inmuebles. ",
          "item4": "Las visitas virtuales son una clara ventaja competitiva ya que eliminan la barrera de tiempo y distancia.",
          "item5": "Visualización total del espacio en el desarrollo inmobiliario.Se incrementa la experiencia de la vivienda y es posible dar una continua retroalimentación para finar detalles del proyecto."
        },
        "titleIframe": "Demostración de Realidad Virtual"
      },
      "photography": {
        "title": "Fotografía",
        "textOne": "Dale una presentación adecuada a tu propiedad e impulsa su renta o venta",
        "textTwo": " La fotografía forma parte del marketing emocional y resulta muy útil para motivar a tus clientes potenciales.",
        "subtitle": "Dale una presentación adecuada a tu propiedad e impulsa su renta o venta ",
        "paragraphs": {
          "first": "La fotografía forma parte del marketing emocional y resulta muy útil para motivar a tus clientes potenciales.",
          "second": "La fotografía inmobiliaria es un recurso visual para emocionar desde la primera etapa del proceso de venta. Ésta, motiva el recorrido mental del inmueble, beneficios y vivencias que se pueden dar en cada espacio."
        }
      },
      "legalServices": {
        "title": "Servicios legales",
        "textOne": "Nuestro equipo legal especializado en bienes raíces para extranjeros y nacionales les ayudará con los siguientes servicios:",
        "textTwo": "Asesoría y elaboración de opiniones legales relacionadas a negociación y contratación de proyectos inmobiliarios, acuerdos de arrendamiento de propiedades, garantías inmobiliarias, entre otros. ",
        "subtitle": "Nuestro equipo legal especializado en bienes raíces para extranjeros y nacionales les ayudará con los siguientes servicios:",
        "list": {
          "item1": "Asesoría y elaboración de opiniones legales relacionadas a negociación y contratación de proyectos inmobiliarios, acuerdos de arrendamiento de propiedades, garantías inmobiliarias, entre otros.",
          "item2": "Investigación registral y catastral de inmuebles.",
          "item3": "Análisis y formalización de compraventas y otras formas de transferencia",
          "sublistItem3": {
            "item1": "Arrendamientos",
            "item2": "Usufructos",
            "item3": "Hipotecas",
            "item4": "Otro tipo de gravámenes"
          },
          "item4": "Planificación de estrategia de venta legal/fiscal del proyecto inmobiliario.",
          "item5": "Constitución de régimen de propiedad horizontal, en condominio o cualquier otro que se requiera, y elaboración de sus respectivos reglamentos"
        }
      },
      "saleRent": {
        "title": "Vendemos y rentamos tu propiedad",
        "textOne": "Vendemos tu inmueble de una forma segura, buscando al cliente adecuado a tus necesidades y seleccionándolos con intención de compra",
        "textTwo": "Nuestro equipo de ventas se encargará de conseguir al cliente ideal para la renta o venta de tu propiedad.",
        "subtitle": "Vendemos y rentamos tu inmueble de una forma segura, buscando al cliente adecuado a tus necesidades y seleccionándolos con intención de compra",
        "paragraphs": {
          "first": "Nuestro equipo de ventas se encargará de conseguir al cliente ideal para la renta o venta de tu propiedad.  ",
          "second": "¿Cuánto cobramos?  "
        },
        "textSale": "Venta",
        "textValueOfSale": "del valor de la venta ",
        "textRent": "Renta",
        "textValueFirstRent": "del valor de la primera renta."
      },
      "management": {
        "title": "Administración y gestión de propiedades",
        "textOne": "¿Tienes una o varias propiedades en Antigua Guatemala y te falta tiempo para encargarte de ellas?",
        "textTwo": "No te preocupes, estamos aquí para ayudarte. Nuestro servicio de administración y gestión de propiedades está diseñado para propietarios ocupados como tú...",
        "subtitle": "¿Tienes una o varias propiedades en Antigua Guatemala y te falta tiempo para encargarte de ellas?",
        "paragraphs": {
          "first": "No te preocupes, estamos aquí para ayudarte. Nuestro servicio de administración y gestión de propiedades está diseñado para propietarios ocupados como tú, que desean maximizar los ingresos sin tener que preocuparse por la tediosa tarea de administrar su propiedad. Desde la comunicación con los huéspedes hasta el manejo eficiente de plataformas de hospedaje como Airbnb, nos aseguramos de que tu propiedad esté siempre visible y atraiga a los viajeros adecuados.",
          "second": "Nuestro equipo se encargará de responder consultas, gestionar reservas y brindar atención excepcional a los huéspedes para garantizar una experiencia de hospedaje inolvidable. Además, nos ocupamos de los aspectos relacionados con la limpieza y mantenimiento de tu propiedad. Nos aseguraremos de que cada rincón esté impecable y listo para recibir a los huéspedes.",
          "third": "Incluso si tu propiedad no está en plataformas de hospedaje y la tienes disponible para rentas de corto, mediano o largo plazo, nuestro servicio sigue siendo la solución perfecta. Nos encargamos de todas las tareas, desde encontrar clientes para la renta de tu propiedad y su administración hasta la gestión de contratos y cobros.",
          "fourth": "Libérate del estrés y deja que nuestro equipo experto en administración y gestión de propiedades se ocupe de todo. Nos aseguramos de que tu propiedad sea una fuente de ingresos sin complicaciones mientras disfrutas de tu tiempo libre."
        }
      },
      "interiorDesign": {
        "title": "Diseño de interiores",
        "textOne": "¿Tienes un 80% del diseño de tu casa lista, pero sientes que aún falta ese encanto exclusivo, ese 'je-ne-sais-quoi' indescriptible?",
        "textTwo": "No te preocupes, estamos aquí para aportar los toques finales que harán que tu hogar cobre vida de verdad! Nuestros servicios de estilismo van más allá de lo convencional y se adentran en la meticulosa búsqueda de esos elementos finales.",
        "subtitle": "¿Tienes un 80% del diseño de tu casa lista, pero sientes que aún falta ese encanto exclusivo, ese 'je-ne-sais-quoi' indescriptible?",
        "paragraphs": {
          "first": "No te preocupes, estamos aquí para aportar los toques finales que harán que tu hogar cobre vida de verdad! Nuestros servicios de estilismo van más allá de lo convencional y se adentran en la meticulosa búsqueda de esos elementos finales y distintivos, desde elementos decorativos seleccionados a mano hasta piezas de diseño cuidadosamente escogidas y cautivadoras obras de arte, que añaden carácter y personalidad a tu espacio vital.",
          "second": "Nuestra estructura de precios es sencilla: nuestros servicios están disponibles a una tarifa de $100 por hora (sin incluir impuestos), con un compromiso mínimo de 4 horas para cada proyecto. Adaptamos nuestro enfoque en función de tus necesidades únicas, considerando el tamaño de tu hogar o las áreas específicas que requieren una transformación estilística, incluyendo la superficie total, el número de habitaciones e incluso las paredes que necesitan ese toque especial. Además, tenemos en cuenta tu presupuesto disponible para la búsqueda, de manera que podemos proporcionarte un presupuesto integral para nuestros servicios de diseño y un presupuesto estimado para la búsqueda, si aún no lo has definido.",
          "third": "Todo lo que te pedimos es que compartas un plano de tu hogar, así como fotos y videos detallados de las áreas que ansían nuestra infusión creativa. Ya sea que tu propiedad sea amplia y expansiva o íntima y acogedora, nuestra misión es convertirlo en un reflejo de tu personalidad y estilo únicos. Con nuestro toque experto, curaremos minuciosamente cada elemento para asegurarnos de que tu hogar irradie calidez, carácter y autenticidad.",
          "fourth": "Permítenos ser tus compañeros creativos en la travesía de elevar tu propiedad a su máximo potencial. ¡No dudes en contactarnos y permitirnos transformar tu hogar en una verdadera obra maestra!",
          "fifth": "Powered by STUDIO MACHTELD OOSTERBAAN"
        }
      }
    },
    "serviceDetail": {
      "backToList": "Volver al listado",
      "requestService": "Solicitar servicio"
    },
    "dialogLoginRegistSession": {
      "textCreate": "Para acceder a esta opción es necesario que previamente inicies sesión (si ya dispones de una cuenta en Luna Nueva haz uso de ella).",
      "textForLogin": "Para guardar propiedades en tu lista de favoritos es necesario que previamente inicies sesión o te registres",
      "textRegister": "Registrarme",
      "textSignupOrLogin": "Inicia sesión o regístrate"
    },
    "servicesFormDialog": {
      "buttonText": "Cerrar",
      "textHeader": "El formulario ha sido enviado con éxito",
      "textBody": "En breve te contactaremos para atender tu solicitud y responder todas tus preguntas",
      "buttonAccept": "Aceptar"
    },
    "contactPageForm": {
      "textErrorOccur": "Ocurrió un error",
      "textErrorExplanation": "Error inesperado. Por favor intente nuevamente",
      "textContactUs": "Contáctanos"
    },
    "contactSellerPage": {
      "textContactSeller": "Contacta al vendedor",
      "textMessage": "Hola,¿Aún sigue disponible?"
    },
    "weekdays": {
      "week": "Semana",
      "monday": "Lu",
      "tuesday": "Mar",
      "wednesday": "Mie",
      "thursday": "Ju",
      "friday": "Vi",
      "saturday": "Sa",
      "sunday": "Do"
    },
    "months": {
      "1": "Enero",
      "2": "Febrero",
      "3": "Marzo",
      "4": "Abril",
      "5": "Mayo",
      "6": "Junio",
      "7": "Julio",
      "8": "Agosto",
      "9": "Septiembre",
      "10": "Octubre",
      "11": "Noviembre",
      "12": "Diciembre"
    }
  }


}
