import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthLayoutComponent } from "./shared/components/layouts/auth-layout/auth-layout.component";
import { NavLayoutComponent } from "./shared/components/layouts/nav-layout/nav-layout.component";
import { NotFoundComponent } from "./views/not-found/not-found.component";
import { ServicesPageFormComponent } from "./views/services-page-form/services-page-form.component";
import { ContactPageFormComponent } from "./views/contact-page-form/contact-page-form.component";
import { ContactSellerPageFormComponent } from "./views/contact-seller-page-form/contact-seller-page-form.component";
import { UserIsLogedIn, UserNotLogedIn } from "./shared/guards/auth.guard";

const routes: Routes = [
    {
        path: '',
        component: NavLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./views/nav/nav.module').then(m => m.NavModule),
            },
            {
                path: 'my-account',
                canActivate: [UserIsLogedIn],
                loadChildren: () => import('./views/my-account/my-account.module').then(m => m.MyAccountModule),
                data: { title: 'Mi Cuenta' }
            },
            {
                path: '',
                loadChildren: () => import('./views/blog/blog.module').then(m => m.BlogModule),
            },
        ]
    },
    {
        path: '',
        component: AuthLayoutComponent,
        children: [
            {
                path: 'sessions',
                canActivate: [UserNotLogedIn],
                loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule),
                data: { title: 'session' }
            },
        ]
    },
    {
        path: 'services-form',
        component: ServicesPageFormComponent,
        data: { title: 'Formulario de contacto' }
    },
    {
        path: 'contact-page-form',
        component: ContactPageFormComponent,
        data: { title: 'Contáctanos' }
    },
    {
        path: 'contact-seller-page-form',
        component: ContactSellerPageFormComponent,
        data: { title: 'Contacta al vendedor' }
    },
    {
        path: '**',
        component: NotFoundComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}