import { Component, OnInit } from '@angular/core';
import { getAnalytics, logEvent } from "@angular/fire/analytics";

import { locale as englishLang } from "../assets/i18n/es";
import { locale as spanishLang } from "../assets/i18n/es";
import { TranslationService } from './shared/services/translation.service';

declare global {
	interface Window {
		onloadTurnstileCallback: () => void;
		turnstile: {
			render: (idOrContainer: string | HTMLElement, options: any) => string;
			reset: (widgetIdOrContainer: string | HTMLElement) => void;
			getResponse: (widgetIdOrContainer: string | HTMLElement) => string | undefined;
			remove: (widgetIdOrContainer: string | HTMLElement) => void;
		};
	}
}

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
	title = 'Luna Nueva';

	constructor(private translationService: TranslationService) {
		// Add translations
		this.translationService.addTranslation(englishLang, spanishLang);

		// Log event
		logEvent(getAnalytics(), "Página cargada");
	}

	ngOnInit(): void {
		window.onloadTurnstileCallback = this.onloadTurnstileCallback.bind(this);

		const script = document.createElement('script');
		script.src = "https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback";
		script.async = true;
		script.defer = true;
		document.body.appendChild(script);
	}

	// Callback function
	onloadTurnstileCallback(): void {
		console.log('Turnstile loaded successfully');
	}


}
