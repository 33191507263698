import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription, switchMap, take, takeUntil } from "rxjs";
import { ContactFormUseCaseService } from "../../core/usecase/contact-form-use-case.service";
import { ModalService } from "../../shared/services/modal.service";
import { DialogService } from "../../shared/services/dialog.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ContactFormTexts } from "../../shared/components/services-form/contact-form-texts";
import { PropertyDetailPostModel } from '../../core/domain/property-detail-post-model';
import { AlojamientosRepository } from '../../core/repositories/alojamientos-post.repository';
import { StoreFiltersService } from '../../shared/services/storeFilters.service';
import { CaptchaService } from '../../shared/services/captcha.service';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'app-contact-seller-page-form',
	templateUrl: './contact-seller-page-form.component.html',
	styleUrls: ['./contact-seller-page-form.component.css']
})
export class ContactSellerPageFormComponent implements OnInit, OnDestroy {
	private destroy$ = new Subject<void>();
	subscriptions: Subscription[] = [];
	title: string = 'Contacta al vendedor';
	message: string = '';
	postId: string = '';
	loading: boolean = false;
	post$!: Observable<PropertyDetailPostModel>;
	card: PropertyDetailPostModel | null | undefined;
	fromDate: any;
	toDate: any;
	cantidadNoches: number = 1;
	total = 0;


	porcentajeLunaNueva = 0.03;
	precioNoche = 0;


	tarifaBase: number = 0;
	promedioNoches: number = 0;
	noches: number = 0;
	tarifaLunaNueva: number = 0;
	tarifaLimpieza: number = 0;
	totalNoches: number = 0;
	tarifaTotal: number = 0;
	costoBase: number = 0;
	tarifas$ = new BehaviorSubject<any>(null);
	idPreReserva: string = '';
	tieneOferta: boolean = false;
	objetoTarifas: any = {};

	defaultImageUrl: string = environment.firebaseConfig.storageBucket + "/PropertiesPortal/" + 'default/' + 'noImage.jpg';
	defaultImageUrlLG: string = environment.firebaseConfig.storageBucket + "/PropertiesPortal/" + 'default/' + 'noImage-lg.jpg';

	constructor(
		private _contactFormUseCaseService: ContactFormUseCaseService,
		private _modal: ModalService,
		private _dialogService: DialogService,
		private _router: Router,
		private _route: ActivatedRoute,
		private _activateRoute: ActivatedRoute,
		private _alojamientosRepository: AlojamientosRepository,
		private storeFilters: StoreFiltersService,
		private _captchaService: CaptchaService,
		private _storeFilterService: StoreFiltersService,

	) {

		let suscription = this._activateRoute.queryParams.subscribe(params => {
			this.postId = params['id'];
		});
		this.subscriptions.push(suscription);
	}


	ngOnInit(): void {
		this.getDetails();
	}

	getCantidadNoches() {
		this.fromDate = this.storeFilters.getFilter().fromDate;
		this.toDate = this.storeFilters.getFilter().toDate;
		// Convertir las cadenas de texto a objetos Date
		let checkIn = new Date(this.fromDate);
		let checkOut = new Date(this.toDate);
		// Calcular la diferencia de tiempo en milisegundos
		const diffTime = Math.abs(checkOut.getTime() - checkIn.getTime());

		// Convertir la diferencia de tiempo en días y redondear hacia arriba
		if (isNaN(Math.ceil(diffTime / (1000 * 60 * 60 * 24)))) {
			console.error("error de fechas");
			this.cantidadNoches = 1;
		}
		else {
			this.cantidadNoches = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

		}
	}


	sendEmails($item: any) {
		const { total } = $item;

		const urlImgMail = this.card?.imagenes[0]?.url?.lg ?? this.defaultImageUrlLG;
		const { captcha, ...rest } = JSON.parse(JSON.stringify($item));
		delete rest['services'];

		const updatedRest = {
			...rest,
			location: this.card?.propiedades.direccion,
			fullDirection: `${this.card?.propiedades.direccion}, ${this.card?.propiedades.ciudad.nombre}`,
		};


		// Aquí puedes incluir los nuevos campos en el objeto rest
		const dataToSend = {
			...updatedRest,
			urlImgMail,
		};

		this.subscriptions.push(
			this._contactFormUseCaseService.postEmailSellerContact(this.postId, dataToSend, captcha)
				.subscribe({
					next: (data) => {
						this._dialogService.openInfoDialog(ContactFormTexts.infoDialog.buttonText, '', ContactFormTexts.infoDialog.textBody, ContactFormTexts.infoDialog.textHeader)
							.pipe(take(1))
							.subscribe(res => this.goToHomePage());
					},
					error: () => {
						this._captchaService.resetCaptcha();
						this._modal.show('contactPageForm.textErrorOccur', 'contactPageForm.textErrorExplanation', "ERROR", 5);
					}
				})
		);
	}


	ngOnDestroy() {
		if (this.subscriptions)
			this.subscriptions.forEach(subs => subs.unsubscribe());
	}

	goToHomePage(): void {
		this._router.navigate(['/']);
	}


	getDetails() {

		this.loading = true;
		this.post$ = this._route.queryParamMap.pipe(
			switchMap(params => {
				const idAlojamiento = params.get('id')!;
				return this._alojamientosRepository.getPostDetails(idAlojamiento);
			})
		);
		this.post$.subscribe(post => {
			this.loading = false;
			this.card = post;
			this.getTarifas();
		});
	}

	async getTarifas() {
		this.tarifas$
			.pipe(takeUntil(this.destroy$), switchMap(id => {
				const fechaDesde = this._storeFilterService.getFilter().fromDate;
				const fechaHasta = this._storeFilterService.getFilter().toDate;
				return this._alojamientosRepository.getAlojamientoTarifa(this.postId, fechaDesde, fechaHasta);
			}))
			.subscribe(data => {
				this.objetoTarifas = {
					noches: data.noches,
					promedioNoches: data.promedioNoches,
					precioNoche: data.promedioNoches,
					tarifaLimpieza: data.tarifaLimpieza,
					tarifaLunaNueva: data.tarifaLunaNueva,
					totalNoches: data.totalNoches,
					tarifaTotal: data.tarifaTotal,
					tieneOferta: data.tarifaBase > data.promedioNoches
				};
			});
	}

}
